
import { Vue, Component } from 'vue-property-decorator'
import { ProjectDetail } from '../../types/project'

@Component
export default class List extends Vue {
  private itemList = [
    {
      label: '项目名称',
      prop: 'projectName'
    }
  ]

  searchForm = {
    projectId: ''
  }

  private tableData: ProjectDetail[] = []
  private loading = false

  get projectList () {
    return this.$store.state.projectList
  }

  created (): void {
    this.getList()
  }

  getList () {
    this.loading = true
    this.$axios.get(this.$apis.irrigation.selectIrrigationProjectWebList, this.searchForm).then(res => {
      this.tableData = res
    }).finally(() => {
      this.loading = false
    })
  }

  onSearch () {
    this.getList()
  }
}
